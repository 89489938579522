import { useMemo } from "react";

import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "@sellernote/_shared/src/services/query";

import { ResponseFailureInfo } from "../../types/common/common";
import {
  ContainerInfoForAN,
  TrelloBidDetail,
  WithdrawalItemList,
} from "../../types/forwarding/trello";

import {
  QueryResponseHandlerCustomMessage,
  QueryResponseHandlerSuccessModalInfo,
} from "../../components/QueryResponseHandler";

import {
  CANCEL_INLAND_TRANSPORT_REQ,
  CANCEL_INLAND_TRANSPORT_RES,
  CHANGE_CUSTOMS_PAYMENT_FLAG_REQ,
  CHANGE_CUSTOMS_PAYMENT_FLAG_RES,
  CHANGE_FOREIGN_WITHDRAWAL_TARGET_REQ,
  CHANGE_FOREIGN_WITHDRAWAL_TARGET_REQ_PATH_PARAMS,
  CHANGE_FOREIGN_WITHDRAWAL_TARGET_RES,
  CHANGE_FTA_CERTIFICATE_FLAG_REQ,
  CHANGE_FTA_CERTIFICATE_FLAG_RES,
  CHANGE_IS_EXPRESS_REQ,
  CHANGE_IS_EXPRESS_RES,
  CHANGE_PURCHASE_PARTNER_REQ,
  CHANGE_PURCHASE_PARTNER_RES,
  CHECK_CARGO_MANIFEST_EXIST_REQ,
  CHECK_CARGO_MANIFEST_EXIST_RES,
  CHECK_REQUESTING_SETTLEMENT_REQ,
  CHECK_REQUESTING_SETTLEMENT_RES,
  CONFIRM_INLAND_REQ,
  CONFIRM_INLAND_RES,
  CONFIRM_SETTLEMENT_REQ,
  CONFIRM_SETTLEMENT_RES,
  CONFIRM_SHIPMENT_SCHEDULE_REQ,
  CONFIRM_SHIPMENT_SCHEDULE_REQ_PATH_PARAMS,
  CONFIRM_SHIPMENT_SCHEDULE_RES,
  CREATE_CARGO_MANIFEST_REQ,
  CREATE_CARGO_MANIFEST_RES,
  DELETE_ACCOUNT_PAYABLE_REQ,
  DELETE_ACCOUNT_PAYABLE_REQ_PATH_PARAMS,
  DELETE_ACCOUNT_PAYABLE_RES,
  EDIT_DEPOSIT_CERTIFICATE_REQ,
  EDIT_DEPOSIT_CERTIFICATE_RES,
  EDIT_INLAND_TYPE_REQ,
  EDIT_INLAND_TYPE_RES,
  GET_CARGO_MANAGEMENT_UNIPASS_PAGE_REQ,
  GET_CARGO_MANAGEMENT_UNIPASS_PAGE_RES,
  GET_CARGO_MANIFEST_REQ,
  GET_CARGO_MANIFEST_RES,
  GET_CLEAR_CAUTION_FOR_LFD_REQ,
  GET_CLEAR_CAUTION_FOR_LFD_RES,
  GET_EXPORT_TRELLO_FINISHED_LIST_REQ,
  GET_EXPORT_TRELLO_FINISHED_LIST_RES,
  GET_EXPORT_TRELLO_LIST_REQ,
  GET_EXPORT_TRELLO_LIST_RES,
  GET_FINANCIAL_DEPOSIT_HISTORY_REQ,
  GET_FINANCIAL_DEPOSIT_HISTORY_RES,
  GET_HBL_INFO_REQ,
  GET_HBL_INFO_RES,
  GET_HOUSE_DELIVERY_ORDER_DOCUMENT_DEFAULT_DATA_RES,
  GET_INFOGRAPHIC_REQ,
  GET_INFOGRAPHIC_RES,
  GET_PARCEL_DOCUMENT_DEFAULT_DATA_RES,
  GET_SCHEDULE_LOG_REQ,
  GET_SCHEDULE_LOG_RES,
  GET_SHIPMENT_SCHEDULE_DETAIL_REQ,
  GET_SHIPMENT_SCHEDULE_DETAIL_RES,
  GET_TRELLO_ATTACHMENTS_REQ,
  GET_TRELLO_ATTACHMENTS_RES,
  GET_TRELLO_BID_DETAIL_REQ,
  GET_TRELLO_BID_FINISHED_LIST_REQ,
  GET_TRELLO_BID_FINISHED_LIST_RES,
  GET_TRELLO_BID_LIST_REQ,
  GET_TRELLO_BID_LIST_RES,
  GET_TRELLO_FREE_TIME_REQ,
  GET_TRELLO_FREE_TIME_RES,
  GET_TRELLO_TRACKING_SHIP_REQ,
  GET_TRELLO_TRACKING_SHIP_RES,
  GET_UNIPASS_CUSTOM_STATUS_LIST_REQ,
  GET_UNIPASS_CUSTOM_STATUS_LIST_RES,
  GET_WAREHOUSE_DOCUMENT_DEFAULT_DATA_RES,
  GET_WITHDRAWAL_BASIC_LIST_REQ,
  GET_WITHDRAWAL_BASIC_LIST_RES,
  MATCH_FINANCIAL_DEPOSIT_REQ,
  MATCH_FINANCIAL_DEPOSIT_RES,
  MOVE_EXPORT_SHIPMENT_TO_FINISHED_REQ,
  MOVE_EXPORT_SHIPMENT_TO_FINISHED_RES,
  MOVE_EXPORT_TRELLO_STATUS_REQ,
  MOVE_EXPORT_TRELLO_STATUS_RES,
  MOVE_TRELLO_STATUS_REQ,
  MOVE_TRELLO_STATUS_RES,
  MOVE_TRELLO_TO_FINISHED_STATUS_REQ,
  MOVE_TRELLO_TO_FINISHED_STATUS_RES,
  REGISTER_FOREIGN_SETTLEMENT_PARTNER_REQ,
  REGISTER_FOREIGN_SETTLEMENT_PARTNER_RES,
  REGISTER_INLAND_REQ,
  REGISTER_INLAND_RES,
  REGISTER_INLAND_TRANSPORT_REQ,
  REGISTER_INLAND_TRANSPORT_RES,
  REGISTER_SHIP_SCHEDULE_REQ,
  REGISTER_SHIP_SCHEDULE_RES,
  REGISTER_SHIPMENT_SCHEDULE_REQ,
  REGISTER_SHIPMENT_SCHEDULE_REQ_PATH_PARAMS,
  REGISTER_SHIPMENT_SCHEDULE_RES,
  REQUEST_DOCUMENT_REVIEW_TO_CUSTOMS_REQ,
  REQUEST_DOCUMENT_REVIEW_TO_CUSTOMS_RES,
  REQUEST_INVOICE_ISSUE_REQ_PATH_PARAMS,
  REQUEST_INVOICE_ISSUE_RES,
  REQUEST_SETTLEMENT_REQ,
  REQUEST_SETTLEMENT_RES,
  REQUEST_WITHDRAW_REQ,
  REQUEST_WITHDRAW_RES,
  SAVE_HBL_REQ,
  SAVE_HBL_RES,
  SEND_HBL_EMAIL_REQ,
  SEND_HBL_EMAIL_RES,
  SEND_INLAND_MAIL_REQ,
  SEND_INLAND_MAIL_RES,
  SEND_REQUIRED_DOCUMENT_EMAIL_REQ,
  SEND_REQUIRED_DOCUMENT_EMAIL_RES,
  SEND_SCHEDULE_MAIL_REQ,
  SEND_SCHEDULE_MAIL_RES,
  TEMP_SAVE_HBL_REQ,
  TEMP_SAVE_HBL_RES,
  TRACKING_UNIPASS_REQ,
  TRACKING_UNIPASS_RES,
  UPDATE_ACCOUNT_PAYABLE_REQ,
  UPDATE_ACCOUNT_PAYABLE_RES,
  UPDATE_AUTO_TRACKING_REQ,
  UPDATE_AUTO_TRACKING_RES,
  UPDATE_BL_NUMBER_REQ,
  UPDATE_BL_NUMBER_RES,
  UPDATE_CARGO_MANIFEST_REQ,
  UPDATE_CARGO_MANIFEST_RES,
  UPDATE_CARGO_READY_REQ,
  UPDATE_CARGO_READY_REQ_PATH_PARAMS,
  UPDATE_CARGO_READY_RES,
  UPDATE_COMMENT_REQ,
  UPDATE_COMMENT_REQ_PATH_PARAMS,
  UPDATE_COMMENT_RES,
  UPDATE_DOCUMENT_REQ,
  UPDATE_DOCUMENT_RES,
  UPDATE_OP_CHECKPOINT_REQ,
  UPDATE_OP_CHECKPOINT_REQ_PATH_PARAMS,
  UPDATE_OP_CHECKPOINT_RES,
  UPDATE_PROJECT_STATUS_TO_MOVING_REQ,
  UPDATE_PROJECT_STATUS_TO_MOVING_RES,
  UPDATE_SHIP_IMO_REQ,
  UPDATE_SHIP_IMO_RES,
  UPDATE_SHIP_SCHEDULE_REQ,
  UPDATE_SHIP_SCHEDULE_RES,
  UPDATE_SHIPMENT_SCHEDULE_REQ,
  UPDATE_SHIPMENT_SCHEDULE_REQ_PATH_PARAMS,
  UPDATE_SHIPMENT_SCHEDULE_RES,
  UPDATE_TRELLO_MEMO_REQ,
  UPDATE_TRELLO_MEMO_RES,
  UPDATE_WAREHOUSE_INFO_REQ,
  UPDATE_WAREHOUSE_INFO_RES,
} from "../../api-interfaces/shipda-api/trello";
import { GET_FREE_TIME_ERROR_CODE } from "../../constants/forwarding/error/freeTime";

export const TRELLO_BID_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/TRELLO_BID_QUERY" }] as const,

  trelloBidList: () =>
    [
      { ...TRELLO_BID_QUERY_KEY_GEN.all()[0], subScope: "trelloBidList" },
    ] as const,

  trelloDetail: () =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.all()[0],
        subScope: "trelloDetail",
      },
    ] as const,

  getTrelloBidList: (params: GET_TRELLO_BID_LIST_REQ) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.trelloBidList()[0],
        ...params,
        entity: "getTrelloBidList",
      },
    ] as const,

  getTrelloBidFinishedList: (params: GET_TRELLO_BID_FINISHED_LIST_REQ) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.trelloBidList()[0],
        ...params,
        entity: "getTrelloBidFinishedList",
      },
    ] as const,

  getExportTrelloList: (params: GET_EXPORT_TRELLO_LIST_REQ) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.trelloBidList()[0],
        ...params,
        entity: "getExportTrelloList",
      },
    ] as const,

  getExportTrelloFinishedList: (params: GET_EXPORT_TRELLO_FINISHED_LIST_REQ) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.trelloBidList()[0],
        ...params,
        entity: "getExportTrelloFinishedList",
      },
    ] as const,

  getTrelloBidDetail: ({ bidId }: GET_TRELLO_BID_DETAIL_REQ) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.trelloDetail()[0],
        bidId,
        entity: "trelloBidDetail",
      },
    ] as const,

  getExportTrelloDetail: ({ bidId }: GET_TRELLO_BID_DETAIL_REQ) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.trelloDetail()[0],
        bidId,
        entity: "exportTrelloDetail",
      },
    ] as const,

  getWithdrawalBasicList: (params: GET_WITHDRAWAL_BASIC_LIST_REQ) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "withdrawalBasicList",
      },
    ] as const,

  getWarehouseDocumentDefaultData: (bidId: number) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.all()[0],
        bidId,
        entity: "warehouseDocumentDefaultData",
      },
    ] as const,

  getParcelDocumentDefaultData: (bidId: number) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.all()[0],
        bidId,
        entity: "parcelDocumentDefaultData",
      },
    ] as const,

  getHouseDeliveryOrderDocumentDefaultData: (bidId: number) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.all()[0],
        bidId,
        entity: "houseDeliveryOrderDocumentDefaultData",
      },
    ] as const,

  getTrelloAttachments: ({ bidId }: GET_TRELLO_ATTACHMENTS_REQ) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.all()[0],
        bidId,
        entity: "trelloAttachments",
      },
    ] as const,

  getFinancialDepositHistory: (
    invoiceId: number,
    params: GET_FINANCIAL_DEPOSIT_HISTORY_REQ
  ) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.all()[0],
        invoiceId,
        ...params,
        entity: "getFinancialDepositHistory",
      },
    ] as const,

  getUnipassCustomStatusList: (params: GET_UNIPASS_CUSTOM_STATUS_LIST_REQ) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getUnipassCustomStatusList",
      },
    ] as const,

  getTrelloTrackingShip: (params: GET_TRELLO_TRACKING_SHIP_REQ) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getTrelloTrackingShip",
      },
    ] as const,

  getShipmentScheduleDetail: (params: GET_SHIPMENT_SCHEDULE_DETAIL_REQ) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getShipmentScheduleDetail",
      },
    ] as const,

  getTrelloFreeTime: (params: GET_TRELLO_FREE_TIME_REQ) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getTrelloFreeTime",
      },
    ] as const,

  getScheduleLog: (params: GET_SCHEDULE_LOG_REQ) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getScheduleLog",
      },
    ] as const,

  getInfographic: (params: GET_INFOGRAPHIC_REQ) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getInfographic",
      },
    ] as const,

  getCargoManagementUnipassPage: (
    params: GET_CARGO_MANAGEMENT_UNIPASS_PAGE_REQ
  ) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "checkCargoManifest",
      },
    ] as const,

  checkCargoManifestExist: (params: { shipmentId: number }) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "checkCargoManifest",
      },
    ] as const,

  getCargoManifest: (params: GET_CARGO_MANIFEST_REQ) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getCargoManifest",
      },
    ] as const,

  getHBLInfo: (params: GET_HBL_INFO_REQ) =>
    [
      {
        ...TRELLO_BID_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getHBLInfo",
      },
    ] as const,
};

function useGetTrelloBidList(
  params: GET_TRELLO_BID_LIST_REQ,
  enabled?: boolean
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getTrelloBidList>,
    GET_TRELLO_BID_LIST_RES
  >({
    keepPreviousData: true,
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getTrelloBidList(params),
    enabled,
    requestOptions: {
      method: "get",
      path: "/bid/list/trello",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
  });

  return { ...queryResult };
}

function useGetTrelloBidFinishedList(
  params: GET_TRELLO_BID_FINISHED_LIST_REQ,
  enabled?: boolean
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getTrelloBidFinishedList>,
    GET_TRELLO_BID_FINISHED_LIST_RES
  >({
    keepPreviousData: true,
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getTrelloBidFinishedList(params),
    enabled,
    requestOptions: {
      method: "get",
      path: "/bid/list/trello",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
  });

  return { ...queryResult };
}

function useGetTrelloBidDetail({
  bidId,
  enabled,
}: GET_TRELLO_BID_DETAIL_REQ & { enabled?: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getTrelloBidDetail>,
    TrelloBidDetail
  >({
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getTrelloBidDetail({ bidId }),
    requestOptions: {
      method: "get",
      path: `/bid/list/trello/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    enabled,
  });

  return { ...queryResult };
}

function useUpdateBLNumber(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo<UPDATE_BL_NUMBER_RES>;

    bidId: number;
  } | void
) {
  const { successModalInfo, bidId } = props || {};

  const mutation = useAppMutation<UPDATE_BL_NUMBER_REQ, UPDATE_BL_NUMBER_RES>({
    requestOptions: {
      method: "put",
      path: `/management/bid/${bidId}/BL`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,
  });

  return { ...mutation };
}

function useRequestSettlement(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo;
    bidId: number;
  } | void
) {
  const { successModalInfo, bidId } = props || {};

  const mutation = useAppMutation<
    REQUEST_SETTLEMENT_REQ,
    REQUEST_SETTLEMENT_RES
  >({
    requestOptions: {
      method: "put",
      path: `/settlement/${bidId}/close`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,

    failureModalInfo: {
      customizeMessage: (failureInfo): QueryResponseHandlerCustomMessage => {
        const getErrorMessage = ({
          errorCode,
        }: {
          errorCode: string | undefined;
        }) =>
          ({
            E081: {
              messageType: "titleOnly" as const,
              title: "입금일이 입력되지 않은 거래명세서가 존재합니다.",
            },
            E082: {
              messageType: "titleOnly" as const,
              title: "입금일이 입력되지 않은 거래명세서가 존재합니다.",
            },
            E083: {
              messageType: "titleOnly" as const,
              title: "발행 요청되지 않은 매출 거래명세서가 존재합니다.",
            },
            E084: {
              messageType: "titleOnly" as const,
              title: "출금 요청되지 않은 매입 거래명세서가 존재합니다.",
            },
            E085: {
              messageType: "titleOnly" as const,
              title: "입금증이 업로드 되지 않아 정산 요청이 불가능합니다.",
            },
            E087: {
              messageType: "titleOnly" as const,
              title: "임시저장 상태인 거래명세서가 있습니다.",
            },
          }[errorCode ?? ""] ?? {
            title: "에러가 발생했습니다 개발자에게 문의해주세요.",
          });

        return getErrorMessage({ errorCode: failureInfo?.errorCode });
      },
    },
  });

  return { ...mutation };
}

function useChangeIsExpress(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo;
    bidId: number;
  } | void
) {
  const { successModalInfo, bidId } = props || {};

  const mutation = useAppMutation<CHANGE_IS_EXPRESS_REQ, CHANGE_IS_EXPRESS_RES>(
    {
      requestOptions: {
        method: "put",
        path: `/bid/${bidId}`,
        apiType: "ShipdaAdminDefaultNew",
      },
      successModalInfo,
    }
  );

  return { ...mutation };
}

function useUpdateProjectStatusToMoving(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo;
    bidId: number;
  } | void
) {
  const { successModalInfo, bidId } = props || {};

  const mutation = useAppMutation<
    UPDATE_PROJECT_STATUS_TO_MOVING_REQ,
    UPDATE_PROJECT_STATUS_TO_MOVING_RES
  >({
    requestOptions: {
      method: "put",
      path: `/bid/${bidId}/moving`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,
  });

  return { ...mutation };
}

function useSendInlandMail(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo;
    bidId: number;
  } | void
) {
  const { successModalInfo, bidId } = props || {};

  const mutation = useAppMutation<SEND_INLAND_MAIL_REQ, SEND_INLAND_MAIL_RES>({
    requestOptions: {
      method: "post",
      path: `/management/${bidId}/email/inlandInfo`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,
  });

  return { ...mutation };
}

function useTrackingUnipass(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo;
    bidId: number;
  } | void
) {
  const { successModalInfo, bidId } = props || {};

  const mutation = useAppMutation<TRACKING_UNIPASS_REQ, TRACKING_UNIPASS_RES>({
    requestOptions: {
      method: "put",
      path: `/management/unipass/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,

    failureModalInfo: {
      customizeMessage: (failureInfo): QueryResponseHandlerCustomMessage => {
        const getErrorMessage = ({
          errorCode,
        }: {
          errorCode: string | undefined;
        }) =>
          ({
            E353: {
              messageType: "titleOnly" as const,
              title: "해당건은 BL문제로 개발자에 별도 문의 부탁드립니다",
            },
            E351: {
              messageType: "titleOnly" as const,
              title: "유니패스 데이터가 없습니다.",
            },
            E352: {
              messageType: "titleOnly" as const,
              title: "고객에게 거래명세서를 발송해주세요.",
            },
          }[errorCode ?? ""] ?? {
            title: "에러가 발생했습니다 개발자에게 문의해주세요.",
          });

        return getErrorMessage({ errorCode: failureInfo?.errorCode });
      },
    },
  });

  return { ...mutation };
}

function useEditInlandType(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo;
    bidId: number;
  } | void
) {
  const { successModalInfo, bidId } = props || {};

  const mutation = useAppMutation<EDIT_INLAND_TYPE_REQ, EDIT_INLAND_TYPE_RES>({
    requestOptions: {
      method: "put",
      path: `/management/${bidId}/inland/detail`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,
  });

  return { ...mutation };
}

function useUpdateShipSchedule(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo;
    bidId: number;
  } | void
) {
  const { successModalInfo, bidId } = props || {};

  const mutation = useAppMutation<
    UPDATE_SHIP_SCHEDULE_REQ,
    UPDATE_SHIP_SCHEDULE_RES
  >({
    requestOptions: {
      method: "put",
      path: `/management/${bidId}/schedule/change`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,
  });

  return { ...mutation };
}

function useRegisterShipSchedule(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo;
    bidId: number;
  } | void
) {
  const { successModalInfo, bidId } = props || {};

  const mutation = useAppMutation<
    REGISTER_SHIP_SCHEDULE_REQ,
    REGISTER_SHIP_SCHEDULE_RES
  >({
    requestOptions: {
      method: "put",
      path: `/management/${bidId}/schedule`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,
  });

  return { ...mutation };
}

//TODO: 이관 API로 변경
function useUpdateShipImo({
  successModalInfo,
  onError,
}: {
  successModalInfo: QueryResponseHandlerSuccessModalInfo<UPDATE_SHIP_IMO_RES>;
  onError?: () => void;
}) {
  const mutation = useAppMutation<UPDATE_SHIP_IMO_REQ, UPDATE_SHIP_IMO_RES>({
    requestOptions: {
      method: "post",
      path: `/ships`,
      apiType: "ShipdaAdminDefaultNew",
    },

    onError: () => {
      if (onError) onError();
    },

    successModalInfo,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.errorCode === "E152")
          return {
            messageType: "titleAndBody",
            title: "유효하지 않은 IMO입니다.",
            body: "IMO가 유효하지 않거나 선박이 존재하지 않습니다.",
          };

        if (failureInfo?.errorCode === "E350")
          return {
            messageType: "titleOnly",
            title: "이미 등록된 IMO입니다.",
          };

        return {
          title: "오류가 발생했습니다. 개발자에게 문의해주세요.",
        };
      },
    },
  });

  return { ...mutation };
}

function useSendRequiredDocumentEmail(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo;
    bidId: number;
  } | void
) {
  const { successModalInfo, bidId } = props || {};

  const mutation = useAppMutation<
    SEND_REQUIRED_DOCUMENT_EMAIL_REQ,
    SEND_REQUIRED_DOCUMENT_EMAIL_RES
  >({
    requestOptions: {
      method: "put",
      path: `/bid/${bidId}/document/email`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,
  });

  return { ...mutation };
}

function useRequestInvoiceIssue(
  props: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo;
  } | void
) {
  const { successModalInfo } = props || {};

  const mutation = useAppMutation<
    unknown,
    REQUEST_INVOICE_ISSUE_RES,
    REQUEST_INVOICE_ISSUE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ invoiceId }) => `/management/${invoiceId}/issue`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,

    failureModalInfo: {
      customizeMessage: (failureInfo): QueryResponseHandlerCustomMessage => {
        const getErrorMessage = ({
          errorCode,
        }: {
          errorCode: string | undefined;
        }) =>
          ({
            E070: {
              messageType: "titleOnly" as const,
              title: "해당 인보이스를 찾을 수 없습니다.",
            },
            E071: {
              messageType: "titleOnly" as const,
              title: "이미 발행 요청된 거래명세서입니다.",
            },
            E090: {
              messageType: "titleOnly" as const,
              title: "고객사에 발송한 거래명세서는 삭제할 수 없습니다.",
            },
            E091: {
              messageType: "titleOnly" as const,
              title:
                "고객사에 발송하지 않은 거래명세서는 발행요청할 수 없습니다.",
            },
            E096: {
              messageType: "titleOnly" as const,
              title:
                "휴폐업 혹은 유효하지 않은 사업자 번호로 거래명세서 발행을 요청했습니다.",
            },
            E097: {
              messageType: "titleOnly" as const,
              title:
                "임시 저장된 거래명세서는 세금계산서를 발행할 수 없습니다.",
            },
          }[errorCode ?? ""] ?? {
            title: "에러가 발생했습니다 개발자에게 문의해주세요.",
          });

        return getErrorMessage({
          errorCode: failureInfo?.errorCode,
        });
      },
    },
  });

  return { ...mutation };
}

function useChangePurchasePartner(bidId: number) {
  const mutation = useAppMutation<
    CHANGE_PURCHASE_PARTNER_REQ,
    CHANGE_PURCHASE_PARTNER_RES
  >({
    requestOptions: {
      method: "put",
      path: `/bid/${bidId}/partners`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useGetWithdrawalBasicList(params: GET_WITHDRAWAL_BASIC_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getWithdrawalBasicList>,
    GET_WITHDRAWAL_BASIC_LIST_RES
  >({
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getWithdrawalBasicList(params),
    requestOptions: {
      method: "get",
      path: "/settlement/purchaseManagement/detail",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
  });

  const withdrawalRequestData = useMemo(() => {
    if (
      !queryResult.data ||
      (queryResult.data && !queryResult.data.withdrawalRequest)
    )
      return;

    return queryResult.data.withdrawalRequest;
  }, [queryResult.data]);

  const isSave = useMemo(() => {
    if (!queryResult.data) {
      return false;
    }

    if (!queryResult.data.withdrawalRequest) {
      return false;
    }

    if (queryResult.data.withdrawalRequest.isWithdrawalRequested) {
      return true;
    }

    return false;
  }, [queryResult.data]);

  const basicWithdrawalRequestList = useMemo(() => {
    if (!queryResult.data) {
      return;
    }
    return queryResult.data.detailList.map((detailItem) => {
      return {
        id: detailItem.id,
        amount: detailItem.amount,
        currency: detailItem.currency,
        finalPrice: detailItem.finalPrice,
        isVAT: detailItem.isVAT,
        itemUnitMeasurement: detailItem.itemUnitMeasurement,
        name: detailItem.name,
        note: detailItem.note,
        totalPrice: detailItem.totalPrice,
        vatPrice: detailItem.vatPrice,
        unitPrice: detailItem.unitPrice,
      };
    });
  }, [queryResult.data]);

  const withdrawalItemList = useMemo(() => {
    if (!queryResult.data) {
      return [];
    }

    if (queryResult.data.itemList.length > 0) {
      return queryResult.data.itemList.map((v: WithdrawalItemList) => {
        return v.name;
      });
    }
    return [];
  }, [queryResult.data]);

  return {
    ...queryResult,
    withdrawalRequestData,
    isSave,
    basicWithdrawalRequestList,
    withdrawalItemList,
  };
}

function useRequestWithdrawal() {
  const mutation = useAppMutation<REQUEST_WITHDRAW_REQ, REQUEST_WITHDRAW_RES>({
    requestOptions: {
      method: "post",
      path: `/settlement/purchaseManagement`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useEditDepositCertificate(
  props: {
    bidId: number;
  } | void
) {
  const { bidId } = props || {};

  const mutation = useAppMutation<
    EDIT_DEPOSIT_CERTIFICATE_REQ,
    EDIT_DEPOSIT_CERTIFICATE_RES
  >({
    requestOptions: {
      method: "put",
      path: `/bid/${bidId}/depositCertificate`,
      apiType: "ShipdaAdminDefaultNew",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo): QueryResponseHandlerCustomMessage => {
        const getErrorMessage = ({
          errorCode,
        }: {
          errorCode: string | undefined;
        }) =>
          ({
            E078: {
              messageType: "titleOnly" as const,
              title: "출금 완료 후 업로드 가능합니다.",
            },
          }[errorCode ?? ""] ?? {
            title: "에러가 발생했습니다 개발자에게 문의해주세요.",
          });

        return getErrorMessage({ errorCode: failureInfo?.errorCode });
      },
    },
  });

  return { ...mutation };
}

function useConfirmSettlement(
  props: {
    bidId: number;
  } | void
) {
  const { bidId } = props || {};

  const mutation = useAppMutation<
    CONFIRM_SETTLEMENT_REQ,
    CONFIRM_SETTLEMENT_RES
  >({
    requestOptions: {
      method: "put",
      path: `/bid/${bidId}/settlement/decision`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useUpdateComment() {
  const mutation = useAppMutation<
    UPDATE_COMMENT_REQ,
    UPDATE_COMMENT_RES,
    UPDATE_COMMENT_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ bidId }) => `management/bid/${bidId}`,

      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useCancelInlandTransport(
  bidId: number,
  successModalInfo?: QueryResponseHandlerSuccessModalInfo
) {
  const mutation = useAppMutation<
    CANCEL_INLAND_TRANSPORT_REQ,
    CANCEL_INLAND_TRANSPORT_RES
  >({
    requestOptions: {
      method: "put",
      path: `management/${bidId}/inland`,
      apiType: "ShipdaAdminDefaultNew",
    },

    successModalInfo,

    failureModalInfo: {
      customizeMessage: (failureInfo): QueryResponseHandlerCustomMessage => {
        const getErrorMessage = ({
          errorCode,
        }: {
          errorCode: string | undefined;
        }) =>
          ({
            E065: {
              messageType: "titleOnly" as const,
              title: "이미 내륙운송이 취소됐습니다.",
            },

            E071: {
              messageType: "titleOnly" as const,
              title: "거래명세서가 이미 발송됐습니다.",
            },
          }[errorCode ?? ""] ?? {
            title: "에러가 발생했습니다 개발자에게 문의해주세요.",
          });

        return getErrorMessage({ errorCode: failureInfo?.errorCode });
      },
    },
  });

  return { ...mutation };
}

function useRegisterInlandTransport(bidId: number) {
  const mutation = useAppMutation<
    REGISTER_INLAND_TRANSPORT_REQ,
    REGISTER_INLAND_TRANSPORT_RES
  >({
    requestOptions: {
      method: "put",
      path: `management/${bidId}/inland/detail`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useConfirmInland(bidId: number) {
  const mutation = useAppMutation<CONFIRM_INLAND_REQ, CONFIRM_INLAND_RES>({
    requestOptions: {
      method: "put",
      path: `/management/${bidId}/inland/confirm`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useChangeCustomsPaymentFlag(bidId: number) {
  const mutation = useAppMutation<
    CHANGE_CUSTOMS_PAYMENT_FLAG_REQ,
    CHANGE_CUSTOMS_PAYMENT_FLAG_RES
  >({
    requestOptions: {
      method: "put",
      path: `/management/bid/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useAddTrelloMemo({
  bidId,
  onSuccess,
}: {
  bidId: number;
  onSuccess: () => void;
}) {
  const mutation = useAppMutation<
    UPDATE_TRELLO_MEMO_REQ,
    UPDATE_TRELLO_MEMO_RES
  >({
    requestOptions: {
      method: "put",
      path: `/management/bid/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    onSuccess,
  });

  return { ...mutation };
}

function useRegisterInland(
  bidId: number,
  successModalInfo?: QueryResponseHandlerSuccessModalInfo
) {
  const mutation = useAppMutation<REGISTER_INLAND_REQ, REGISTER_INLAND_RES>({
    requestOptions: {
      method: "put",
      path: `/management/${bidId}/inland`,
      apiType: "ShipdaAdminDefaultNew",
    },

    successModalInfo,

    failureModalInfo: {
      customizeMessage: (failureInfo): QueryResponseHandlerCustomMessage => {
        const getErrorMessage = ({
          errorCode,
        }: {
          errorCode: string | undefined;
        }) =>
          ({
            E065: {
              messageType: "titleOnly" as const,
              title: "변경된 값이 없습니다.",
            },

            E071: {
              messageType: "titleOnly" as const,
              title: "거래명세서가 이미 발송됐습니다.",
            },
          }[errorCode ?? ""] ?? {
            title: "에러가 발생했습니다 개발자에게 문의해주세요.",
          });

        return getErrorMessage({ errorCode: failureInfo?.errorCode });
      },
    },
  });

  return { ...mutation };
}

function useTrackingUnipassContainerNumber(props: {
  successModalInfo: QueryResponseHandlerSuccessModalInfo;
  bidId: number;
}) {
  const { successModalInfo, bidId } = props;

  const mutation = useAppMutation<TRACKING_UNIPASS_REQ, TRACKING_UNIPASS_RES>({
    requestOptions: {
      method: "put",
      path: `/management/unipass/containerNumber/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo,

    failureModalInfo: {
      customizeMessage: (failureInfo): QueryResponseHandlerCustomMessage => {
        const getErrorMessage = ({
          errorCode,
        }: {
          errorCode: string | undefined;
        }) =>
          ({
            E353: {
              messageType: "titleOnly" as const,
              title: "해당건은 BL문제로 개발자에 별도 문의 부탁드립니다",
            },
            E351: {
              messageType: "titleOnly" as const,
              title: "유니패스 데이터가 없습니다.",
            },
            E360: {
              messageType: "titleOnly" as const,
              title:
                "컨테이너 번호를 업데이트하기 위해선 화물관리번호가 필수입니다.",
            },
          }[errorCode ?? ""] ?? {
            title: "에러가 발생했습니다 개발자에게 문의해주세요.",
          });

        return getErrorMessage({ errorCode: failureInfo?.errorCode });
      },
    },
  });

  return { ...mutation };
}

function useChangeFTACertificateFlag(
  bidId: number,
  successModalInfo?: QueryResponseHandlerSuccessModalInfo
) {
  const mutation = useAppMutation<
    CHANGE_FTA_CERTIFICATE_FLAG_REQ,
    CHANGE_FTA_CERTIFICATE_FLAG_RES
  >({
    requestOptions: {
      method: "put",
      path: `/bid/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    successModalInfo,

    failureModalInfo: {
      customizeMessage: (failureInfo): QueryResponseHandlerCustomMessage => {
        const getErrorMessage = ({
          error,
          errorCode,
        }: {
          error: string | undefined;
          errorCode: string | undefined;
        }) =>
          ({
            "bid cannot be modified at this stage": {
              messageType: "titleOnly" as const,
              title: "현재 상태에서는 변경이 불가능합니다.",
            },
          }[errorCode ?? error ?? ""] ?? {
            title: "에러가 발생했습니다 개발자에게 문의해주세요.",
          });

        return getErrorMessage({
          error: failureInfo?.error,
          errorCode: failureInfo?.errorCode,
        });
      },
    },
  });

  return { ...mutation };
}

function useGetWarehouseDocumentDefaultData(bidId: number) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getWarehouseDocumentDefaultData>,
    GET_WAREHOUSE_DOCUMENT_DEFAULT_DATA_RES
  >({
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getWarehouseDocumentDefaultData(bidId),
    requestOptions: {
      method: "get",
      path: `/bid/document/releaseRequest/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

function useGetParcelDocumentDefaultData(bidId: number) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getParcelDocumentDefaultData>,
    GET_PARCEL_DOCUMENT_DEFAULT_DATA_RES
  >({
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getParcelDocumentDefaultData(bidId),
    requestOptions: {
      method: "get",
      path: `/bid/document/parcelRequest/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

function useGetHouseDeliveryOrderDocumentDefaultData(bidId: number) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof TRELLO_BID_QUERY_KEY_GEN.getHouseDeliveryOrderDocumentDefaultData
    >,
    GET_HOUSE_DELIVERY_ORDER_DOCUMENT_DEFAULT_DATA_RES
  >({
    queryKey:
      TRELLO_BID_QUERY_KEY_GEN.getHouseDeliveryOrderDocumentDefaultData(bidId),
    requestOptions: {
      method: "get",
      path: `/bid/document/houseDo/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

function useUpdateDocument(bidId: number) {
  const mutation = useAppMutation<UPDATE_DOCUMENT_REQ, UPDATE_DOCUMENT_RES>({
    requestOptions: {
      method: "post",
      path: `/file/document/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useUpdateWarehouseInfo(bidId: number) {
  const mutation = useAppMutation<
    UPDATE_WAREHOUSE_INFO_REQ,
    UPDATE_WAREHOUSE_INFO_RES
  >({
    requestOptions: {
      method: "put",
      path: `/management/unipass/warehouse/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useGetTrelloAttachments({ bidId }: GET_TRELLO_ATTACHMENTS_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getTrelloAttachments>,
    GET_TRELLO_ATTACHMENTS_RES
  >({
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getTrelloAttachments({ bidId }),
    requestOptions: {
      method: "get",
      path: `/file/${bidId}/attachment`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

function useUpdateAccountPayable(bidId: number) {
  const mutation = useAppMutation<
    UPDATE_ACCOUNT_PAYABLE_REQ,
    UPDATE_ACCOUNT_PAYABLE_RES
  >({
    requestOptions: {
      method: "post",
      path: `/settlement/purchaseManagement/accountPayable/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useChangeForeignWithdrawalTarget() {
  const mutation = useAppMutation<
    CHANGE_FOREIGN_WITHDRAWAL_TARGET_REQ,
    CHANGE_FOREIGN_WITHDRAWAL_TARGET_RES,
    CHANGE_FOREIGN_WITHDRAWAL_TARGET_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ bidAccountPayableId }) =>
        `/settlement/purchaseManagement/accountPayable/${bidAccountPayableId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useRegisterForeignSettlementPartner(bidId: number) {
  const mutation = useAppMutation<
    REGISTER_FOREIGN_SETTLEMENT_PARTNER_REQ,
    REGISTER_FOREIGN_SETTLEMENT_PARTNER_RES
  >({
    requestOptions: {
      method: "post",
      path: `/settlement/purchaseManagement/accountPayable/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useDeleteAccountPayable() {
  const mutation = useAppMutation<
    DELETE_ACCOUNT_PAYABLE_REQ,
    DELETE_ACCOUNT_PAYABLE_RES,
    DELETE_ACCOUNT_PAYABLE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "delete",
      path: ({ bidAccountPayableId }) =>
        `/settlement/purchaseManagement/accountPayable/${bidAccountPayableId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useGetFinancialDepositHistory({
  invoiceId,
  params,
  onSuccess,
  enabled,
}: {
  invoiceId: number;
  params: GET_FINANCIAL_DEPOSIT_HISTORY_REQ;
  onSuccess?: (data: GET_FINANCIAL_DEPOSIT_HISTORY_RES) => void;
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getFinancialDepositHistory>,
    GET_FINANCIAL_DEPOSIT_HISTORY_RES
  >({
    keepPreviousData: true,
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getFinancialDepositHistory(
      invoiceId,
      params
    ),
    requestOptions: {
      method: "get",
      path: `/financial/deposit/history/${invoiceId}`,
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    enabled,
    onSuccess,
  });

  return { ...queryResult };
}

function useMatchFinancialDeposit() {
  const mutation = useAppMutation<
    MATCH_FINANCIAL_DEPOSIT_REQ,
    MATCH_FINANCIAL_DEPOSIT_RES
  >({
    requestOptions: {
      method: "put",
      path: "/financial/deposit/history/matching",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useCheckRequestingSettlement(bidId: number) {
  const mutation = useAppMutation<
    CHECK_REQUESTING_SETTLEMENT_REQ,
    CHECK_REQUESTING_SETTLEMENT_RES
  >({
    requestOptions: {
      method: "post",
      path: `/settlement/${bidId}/validate`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useGetUnipassCustomStatusList(
  params: GET_UNIPASS_CUSTOM_STATUS_LIST_REQ
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getUnipassCustomStatusList>,
    GET_UNIPASS_CUSTOM_STATUS_LIST_RES
  >({
    keepPreviousData: true,
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getUnipassCustomStatusList(params),
    requestOptions: {
      method: "get",
      path: `/management/unipass/custom/status/${params.bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

function useMoveTrelloToFinishedStatus({
  bidId,
  onSuccess,
}: {
  bidId: number;
  onSuccess: () => void;
}) {
  const mutation = useAppMutation<
    MOVE_TRELLO_TO_FINISHED_STATUS_REQ,
    MOVE_TRELLO_TO_FINISHED_STATUS_RES
  >({
    requestOptions: {
      method: "put",
      path: `/bid/${bidId}/finish`,
      apiType: "ShipdaAdminDefaultNew",
    },

    onSuccess: () => {
      if (onSuccess) onSuccess();
    },
  });

  return { ...mutation };
}

function useRequestDocumentReviewToCustoms({
  bidId,
  onSuccess,
  onError,
  disablesModal,
}: {
  bidId: number;
  onSuccess: () => void;
  onError: () => void;
  disablesModal: () => void;
}) {
  const mutation = useAppMutation<
    REQUEST_DOCUMENT_REVIEW_TO_CUSTOMS_REQ,
    REQUEST_DOCUMENT_REVIEW_TO_CUSTOMS_RES
  >({
    requestOptions: {
      method: "put",
      path: `/bid/${bidId}/document/review`,
      apiType: "ShipdaAdminDefaultNew",
    },

    onSuccess,
    onError,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.error === "already requested") {
          return {
            title: "서류검토 요청이 완료된 의뢰입니다.",
            messageType: "titleOnly",
          };
        }

        return {
          title: "요청 중 오류가 발생했습니다.",
          messageType: "titleOnly",
        };
      },

      handleConfirmFailure: () => {
        disablesModal();
      },
    },
  });

  return { ...mutation };
}

function useMoveTrelloStatus({
  bidId,
  onSuccess,
}: {
  bidId: number;
  onSuccess: () => void;
}) {
  const mutation = useAppMutation<
    MOVE_TRELLO_STATUS_REQ,
    MOVE_TRELLO_STATUS_RES
  >({
    requestOptions: {
      method: "put",
      path: `/bid/${bidId}/status`,
      apiType: "ShipdaAdminDefaultNew",
    },

    onSuccess: () => {
      if (onSuccess) onSuccess();
    },
  });

  return { ...mutation };
}

function useGetExportTrelloList(
  params: GET_EXPORT_TRELLO_LIST_REQ,
  enabled?: boolean
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getExportTrelloList>,
    GET_EXPORT_TRELLO_LIST_RES
  >({
    keepPreviousData: true,
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getExportTrelloList(params),
    enabled,
    requestOptions: {
      method: "get",
      path: "/export/trello",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
  });

  return { ...queryResult };
}

function useGetExportTrelloFinishedList(
  params: GET_EXPORT_TRELLO_FINISHED_LIST_REQ,
  enabled?: boolean
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getExportTrelloFinishedList>,
    GET_EXPORT_TRELLO_FINISHED_LIST_RES
  >({
    keepPreviousData: true,
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getExportTrelloFinishedList(params),
    enabled,
    requestOptions: {
      method: "get",
      path: "/export/trello",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
  });

  return { ...queryResult };
}

function useGetExportTrelloDetail({
  bidId,
  enabled,
}: GET_TRELLO_BID_DETAIL_REQ & { enabled?: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getExportTrelloDetail>,
    TrelloBidDetail
  >({
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getExportTrelloDetail({ bidId }),
    requestOptions: {
      method: "get",
      path: `/export/trello/${bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    enabled,
  });

  return { ...queryResult };
}

function useMoveExportTrelloStatus({
  bidId,
  onSuccess,
}: {
  bidId: number;
  onSuccess: () => void;
}) {
  const mutation = useAppMutation<
    MOVE_EXPORT_TRELLO_STATUS_REQ,
    MOVE_EXPORT_TRELLO_STATUS_RES
  >({
    requestOptions: {
      method: "put",
      path: `/export/${bidId}/status`,
      apiType: "ShipdaAdminDefaultNew",
    },

    onSuccess: () => {
      if (onSuccess) onSuccess();
    },
  });

  return { ...mutation };
}

function useMoveExportShipmentToFinished({
  shipmentId,
  onSuccess,
}: {
  shipmentId: number;
  onSuccess: () => void;
}) {
  const mutation = useAppMutation<
    MOVE_EXPORT_SHIPMENT_TO_FINISHED_REQ,
    MOVE_EXPORT_SHIPMENT_TO_FINISHED_RES
  >({
    requestOptions: {
      method: "put",
      path: `/export/${shipmentId}/finish`,
      apiType: "ShipdaAdminDefaultNew",
    },

    onSuccess: () => {
      if (onSuccess) onSuccess();
    },
  });

  return { ...mutation };
}

function useGetTrelloTrackingShip({
  trelloId,
  enabled = false,
}: GET_TRELLO_TRACKING_SHIP_REQ & { enabled?: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getTrelloTrackingShip>,
    GET_TRELLO_TRACKING_SHIP_RES
  >({
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getTrelloTrackingShip({ trelloId }),
    requestOptions: {
      path: `tracking/v2/ship/${trelloId}`,
      method: "get",
      apiType: "ShipdaAdminDefaultNew",
    },
    enabled,
  });
  return queryResult;
}

function useGetTrelloFreeTime({
  shipmentId,
  enabled = false,
  onError,
  hidesFailureModal,
}: GET_TRELLO_FREE_TIME_REQ & {
  enabled?: boolean;
  onError?: (error: ResponseFailureInfo | undefined) => void;
  hidesFailureModal?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getTrelloFreeTime>,
    GET_TRELLO_FREE_TIME_RES
  >({
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getTrelloFreeTime({ shipmentId }),
    requestOptions: {
      path: `freetime/${shipmentId}`,
      method: "get",
      apiType: "ShipdaAdminDefaultNew",
    },
    enabled,
    onError,
    hidesFailureModal,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        return failureInfo?.error
          ? { messageType: "titleOnly", title: failureInfo.error }
          : GET_FREE_TIME_ERROR_CODE["default"];
      },
    },
  });

  return queryResult;
}

/**
 * 운영 매니저가 프리타임 데드라인 주의 표기를 해제할 때.
 */
function useClearCautionForLFD({ onSuccess }: { onSuccess?: () => void }) {
  const mutation = useAppMutation<
    GET_CLEAR_CAUTION_FOR_LFD_REQ,
    GET_CLEAR_CAUTION_FOR_LFD_RES
  >({
    requestOptions: {
      method: "patch",
      path: `freetime/checkedAt`,
      apiType: "ShipdaAdminDefaultNew",
    },

    onSuccess,
  });

  return { ...mutation };
}

/** 트렐로 상세 > 운송 스케줄 > 카고레디 최초 입력 */
function useCreateCargoReady() {
  const mutation = useAppMutation<
    UPDATE_CARGO_READY_REQ,
    UPDATE_CARGO_READY_RES,
    UPDATE_CARGO_READY_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "post",
      path: ({ shipmentId }) => `/management/${shipmentId}/schedule/cargoReady`,
      apiType: "ShipdaAdminDefaultNew",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        return {
          messageType: "titleAndBody",
          title: "오류가 발생했습니다.",
          body: `에러코드: ${failureInfo?.code}`,
        };
      },
    },
  });

  return { ...mutation };
}

/** 트렐로 상세 > 운송 스케줄 > 카고레디 변경 */
function useUpdateCargoReady() {
  const mutation = useAppMutation<
    UPDATE_CARGO_READY_REQ,
    UPDATE_CARGO_READY_RES,
    UPDATE_CARGO_READY_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ shipmentId }) => `/management/${shipmentId}/schedule/cargoReady`,
      apiType: "ShipdaAdminDefaultNew",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        return {
          messageType: "titleAndBody",
          title: "오류가 발생했습니다.",
          body: `에러코드: ${failureInfo?.code}`,
        };
      },
    },
  });

  return { ...mutation };
}

/** 트렐로 > 트렐로 카드 > 파란색 테두리, 트렐로 상세 > 운송스케줄 강조 표기 삭제 API */
function useConfirmShipmentSchedule() {
  const mutation = useAppMutation<
    CONFIRM_SHIPMENT_SCHEDULE_REQ,
    CONFIRM_SHIPMENT_SCHEDULE_RES,
    CONFIRM_SHIPMENT_SCHEDULE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ shipmentId }) => `/management/shipment/${shipmentId}/schedule`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

/** 트렐로 > 트렐로 상세 > 운송 스케줄 데이터 */
function useGetShipmentScheduleDetail({
  shipmentId,
  onSuccess,
}: GET_SHIPMENT_SCHEDULE_DETAIL_REQ & {
  onSuccess: (data: GET_SHIPMENT_SCHEDULE_DETAIL_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getShipmentScheduleDetail>,
    GET_SHIPMENT_SCHEDULE_DETAIL_RES
  >({
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getShipmentScheduleDetail({
      shipmentId,
    }),
    requestOptions: {
      method: "get",
      path: `/bid/trello/schedule/${shipmentId}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    onSuccess: (data) => {
      if (onSuccess) onSuccess(data);
    },
  });

  return { ...queryResult };
}

/**
 * 트렐로 > 트렐로 상세 > 운송 스케줄 > 스케줄 최초 입력
 */
function useRegisterShipmentSchedule() {
  const mutation = useAppMutation<
    REGISTER_SHIPMENT_SCHEDULE_REQ,
    REGISTER_SHIPMENT_SCHEDULE_RES,
    REGISTER_SHIPMENT_SCHEDULE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "post",
      path: ({ shipmentId }) => `/management/${shipmentId}/schedule`,
      apiType: "ShipdaAdminDefaultNew",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.errorCode === "E374") {
          return {
            messageType: "titleAndBody",
            title: "오류가 발생했습니다.",
            body: `수출자 컨택 중 상태에서만 스케줄 입력이 가능합니다.`,
          };
        }

        if (failureInfo?.errorCode === "E375") {
          return {
            messageType: "titleAndBody",
            title: "오류가 발생했습니다.",
            body: `등록되지 않은 선박입니다.`,
          };
        }

        return {
          messageType: "titleAndBody",
          title: "오류가 발생했습니다.",
          body: `에러코드: ${failureInfo?.code}`,
        };
      },
    },
  });

  return { ...mutation };
}

/**
 * 트렐로 > 트렐로 상세 > 운송 스케줄 > 스케줄 변경
 */
function useUpdateShipmentSchedule() {
  const mutation = useAppMutation<
    UPDATE_SHIPMENT_SCHEDULE_REQ,
    UPDATE_SHIPMENT_SCHEDULE_RES,
    UPDATE_SHIPMENT_SCHEDULE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ shipmentId }) => `/management/${shipmentId}/schedule`,
      apiType: "ShipdaAdminDefaultNew",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.errorCode === "E375") {
          return {
            messageType: "titleAndBody",
            title: "오류가 발생했습니다.",
            body: `등록되지 않은 선박입니다.`,
          };
        }

        return {
          messageType: "titleAndBody",
          title: "오류가 발생했습니다.",
          body: `에러코드: ${failureInfo?.code}`,
        };
      },
    },
  });

  return { ...mutation };
}

function useSendScheduleMail({
  shipmentId,
  onSuccess,
}: {
  shipmentId: number;
  onSuccess: () => void;
}) {
  const mutation = useAppMutation<
    SEND_SCHEDULE_MAIL_REQ,
    SEND_SCHEDULE_MAIL_RES
  >({
    requestOptions: {
      method: "patch",
      path: `/management/${shipmentId}/schedule/send`,
      apiType: "ShipdaAdminDefaultNew",
    },

    onSuccess,
  });

  return { ...mutation };
}

function useGetScheduleLog({
  page,
  perPage,
  shipmentId,
}: GET_SCHEDULE_LOG_REQ & { shipmentId: number }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getScheduleLog>,
    GET_SCHEDULE_LOG_RES
  >({
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getScheduleLog({
      page,
      perPage,
    }),
    requestOptions: {
      method: "get",
      path: `/bid/scheduleLog/${shipmentId}`,
      apiType: "ShipdaAdminDefaultNew",
      params: { page, perPage },
    },
  });

  return { ...queryResult };
}

function useGetInfographic({ shipmentId }: GET_INFOGRAPHIC_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getInfographic>,
    GET_INFOGRAPHIC_RES
  >({
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getInfographic({ shipmentId }),
    requestOptions: {
      method: "get",
      path: `/bid/transportation/${shipmentId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

/**
 * 트렐로 > 트렐로 상세 > 운송 스케줄 > 자동 갱신
 */
function useUpdateAutoTracking({ shipmentId }: { shipmentId: number }) {
  const mutation = useAppMutation<
    UPDATE_AUTO_TRACKING_REQ,
    UPDATE_AUTO_TRACKING_RES
  >({
    requestOptions: {
      method: "put",
      path: `/tracking/${shipmentId}/activate`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useUpdateOpCheckPoint() {
  const mutation = useAppMutation<
    UPDATE_OP_CHECKPOINT_REQ,
    UPDATE_OP_CHECKPOINT_RES,
    UPDATE_OP_CHECKPOINT_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ shipmentId }) => `/op-checkpoint/check/${shipmentId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

/** 특정 화물번호로 검색한 유니패스 화면을 스크랩핑, html 문자열로 만들어 반환하는 API (#14280) */
function useGetCargoManagementUnipassPage({
  cargoMtNo,
  enabled,
  onSuccess,
  onError,
}: GET_CARGO_MANAGEMENT_UNIPASS_PAGE_REQ & {
  enabled: boolean;
  onSuccess: (data: string) => void;
  onError: () => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getCargoManagementUnipassPage>,
    GET_CARGO_MANAGEMENT_UNIPASS_PAGE_RES
  >({
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getCargoManagementUnipassPage({
      cargoMtNo,
    }),
    requestOptions: {
      method: "get",
      path: `/management/unipass/html?cargMtNo=${cargoMtNo}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),

    onSuccess,
    onError,
  });

  return { ...queryResult };
}

/**
 * 트렐로 상세 > 적하목록 제출 버튼 클릭시 요청
 * 적하목록 정보가 있는지 확인하는 API
 */
function useCheckCargoManifestExist({
  shipmentId,
  enabled,
  onSuccess,
}: CHECK_CARGO_MANIFEST_EXIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.checkCargoManifestExist>,
    CHECK_CARGO_MANIFEST_EXIST_RES
  >({
    queryKey: TRELLO_BID_QUERY_KEY_GEN.checkCargoManifestExist({ shipmentId }),
    requestOptions: {
      method: "get",
      path: `/cargoManifest/exist/${shipmentId}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),
    onSuccess,
  });

  return { ...queryResult };
}

/**
 * 트렐로 > 트렐로 상세 > 적하목록 조회
 */
function useGetCargoManifest({
  shipmentId,
  onSuccess,
  enabled,
}: {
  shipmentId: number;
  onSuccess: (data: GET_CARGO_MANIFEST_RES) => void;
  enabled: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getCargoManifest>,
    GET_CARGO_MANIFEST_RES
  >({
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getCargoManifest({ shipmentId }),
    requestOptions: {
      method: "get",
      path: `/cargoManifest/${shipmentId}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    onSuccess,
    ...(typeof enabled === "boolean" ? { enabled } : {}),

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.errorCode === "E378") {
          return {
            messageType: "titleOnly",
            title: "적하목록 조회 정보가 없습니다.",
          };
        }

        return {
          messageType: "titleAndBody",
          title: "오류가 발생했습니다.",
          body: `에러코드: ${failureInfo?.code}`,
        };
      },
    },
  });

  return { ...queryResult };
}

/**
 * 트렐로 > 트렐로 상세 > 적하목록 생성
 * @function useCheckCargoManifestExist 의 응답이 false인 경우 호출
 */
function useCreateCargoManifest({ shipmentId }: { shipmentId: number }) {
  const mutation = useAppMutation<
    CREATE_CARGO_MANIFEST_REQ,
    CREATE_CARGO_MANIFEST_RES
  >({
    requestOptions: {
      method: "post",
      path: `/cargoManifest/${shipmentId}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.errorCode === "E379") {
          return {
            messageType: "titleOnly",
            title: "적하목록 정보 생성에 필요한 정보를 입력해주세요.",
          };
        }

        return {
          messageType: "titleAndBody",
          title: "오류가 발생했습니다.",
          body: `에러코드: ${failureInfo?.code}`,
        };
      },
    },
  });

  return { ...mutation };
}

/**
 * 트렐로 > 트렐로 상세 > 적하목록 조회 > 적하목록 정보 수정
 */
function useUpdateCargoManifest<T>({
  shipmentId,
  onSnackbarOpen,
  setIsEditMode,
}: {
  shipmentId: number;
  onSnackbarOpen: (message: string, color?: T) => void;
  setIsEditMode: (isEditMode: boolean) => void;
}) {
  const mutation = useAppMutation<
    Partial<
      Record<
        keyof UPDATE_CARGO_MANIFEST_REQ,
        string | number | ContainerInfoForAN[] | null
      >
    >,
    UPDATE_CARGO_MANIFEST_RES
  >({
    requestOptions: {
      method: "put",
      path: `/cargoManifest/${shipmentId}`,
      apiType: "ShipdaAdminDefaultNew",
    },

    onError(failureInfo) {
      setIsEditMode(true);

      if (failureInfo?.errorCode === "E376") {
        onSnackbarOpen("포장 개수 합을 확인해주세요.", "error" as T);
        return;
      }

      if (failureInfo?.errorCode === "E378") {
        onSnackbarOpen("적하목록 조회 정보가 없습니다.", "error" as T);
        return;
      }

      onSnackbarOpen(
        `에러코드: ${failureInfo?.code}, 개발자에게 문의해주세요.`,
        "error" as T
      );
    },
  });

  return { ...mutation };
}

/**
 * 트렐로 > 트렐로 상세 > 적하목록 조회 > 적하목록 신청
 */
function useRequestCargoManifest<T>({
  shipmentId,
  onSnackbarOpen,
}: {
  shipmentId: number;
  onSnackbarOpen: (message: string, color?: T) => void;
}) {
  const mutation = useAppMutation<Record<string, never>, Record<string, never>>(
    {
      requestOptions: {
        method: "post",
        path: `/cargoManifest/request/${shipmentId}`,
        apiType: "ShipdaAdminDefaultNew",
      },

      onError(failureInfo) {
        if (failureInfo?.errorCode === "E376") {
          onSnackbarOpen("포장 개수 합을 확인해주세요.", "error" as T);
          return;
        }

        if (failureInfo?.errorCode === "E377") {
          onSnackbarOpen("제출 가능한 상태가 아닙니다.", "error" as T);
          return;
        }

        if (failureInfo?.errorCode === "E378") {
          onSnackbarOpen("적하목록 조회 정보가 없습니다.", "error" as T);
          return;
        }

        if (failureInfo?.errorCode === "E379") {
          onSnackbarOpen(
            "적하목록 제출에 필요한 정보를 확인해주세요.",
            "error" as T
          );
          return;
        }

        onSnackbarOpen(
          "에러가 발생했습니다. 개발자에게 문의해주세요.",
          "error" as T
        );
      },
    }
  );

  return { ...mutation };
}

function useSaveHBL({ shipmentId }: { shipmentId: number }) {
  const mutation = useAppMutation<SAVE_HBL_REQ, SAVE_HBL_RES>({
    requestOptions: {
      method: "post",
      path: `/bl/${shipmentId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useTempSaveHBL({ shipmentId }: { shipmentId: number }) {
  const mutation = useAppMutation<TEMP_SAVE_HBL_REQ, TEMP_SAVE_HBL_RES>({
    requestOptions: {
      method: "post",
      path: `/bl/temp/${shipmentId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

function useGetHBLInfo({ shipmentId }: GET_HBL_INFO_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof TRELLO_BID_QUERY_KEY_GEN.getHBLInfo>,
    GET_HBL_INFO_RES
  >({
    queryKey: TRELLO_BID_QUERY_KEY_GEN.getHBLInfo({ shipmentId }),
    requestOptions: {
      method: "get",
      path: `/bl/${shipmentId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

function useSendHBLEmail({ shipmentId }: { shipmentId: number }) {
  const mutation = useAppMutation<SEND_HBL_EMAIL_REQ, SEND_HBL_EMAIL_RES>({
    requestOptions: {
      method: "post",
      path: `/bl/send/${shipmentId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...mutation };
}

const TRELLO_BID_QUERY = {
  useUpdateBLNumber,
  useRequestSettlement,
  useGetTrelloBidFinishedList,
  useGetTrelloBidList,
  useGetTrelloBidDetail,
  useChangeIsExpress,
  useUpdateProjectStatusToMoving,
  useSendInlandMail,
  useTrackingUnipass,
  useEditInlandType,
  useUpdateShipSchedule,
  useUpdateShipImo,
  useSendRequiredDocumentEmail,
  useRequestInvoiceIssue,
  useChangePurchasePartner,
  useGetWithdrawalBasicList,
  useRequestWithdrawal,
  useEditDepositCertificate,
  useConfirmSettlement,
  useUpdateComment,
  useCancelInlandTransport,
  useRegisterInlandTransport,
  useConfirmInland,
  useChangeCustomsPaymentFlag,
  useAddTrelloMemo,
  useRegisterInland,
  useTrackingUnipassContainerNumber,
  useChangeFTACertificateFlag,
  useGetWarehouseDocumentDefaultData,
  useGetParcelDocumentDefaultData,
  useGetHouseDeliveryOrderDocumentDefaultData,
  useUpdateDocument,
  useUpdateWarehouseInfo,
  useGetTrelloAttachments,
  useUpdateAccountPayable,
  useChangeForeignWithdrawalTarget,
  useRegisterForeignSettlementPartner,
  useDeleteAccountPayable,
  useGetFinancialDepositHistory,
  useMatchFinancialDeposit,
  useCheckRequestingSettlement,
  useGetUnipassCustomStatusList,
  useMoveTrelloToFinishedStatus,
  useRequestDocumentReviewToCustoms,
  useMoveTrelloStatus,
  useRegisterShipSchedule,
  useGetExportTrelloList,
  useGetExportTrelloFinishedList,
  useGetExportTrelloDetail,
  useMoveExportTrelloStatus,
  useMoveExportShipmentToFinished,
  useGetTrelloTrackingShip,
  useGetTrelloFreeTime,
  useClearCautionForLFD,
  useCreateCargoReady,
  useUpdateCargoReady,
  useConfirmShipmentSchedule,
  useGetShipmentScheduleDetail,
  useRegisterShipmentSchedule,
  useUpdateShipmentSchedule,
  useSendScheduleMail,
  useGetScheduleLog,
  useGetInfographic,
  useUpdateAutoTracking,
  useUpdateOpCheckPoint,
  useGetCargoManagementUnipassPage,
  useCheckCargoManifestExist,
  useGetCargoManifest,
  useCreateCargoManifest,
  useUpdateCargoManifest,
  useRequestCargoManifest,
  useGetHBLInfo,
  useSaveHBL,
  useSendHBLEmail,
  useTempSaveHBL,
};

export default TRELLO_BID_QUERY;
